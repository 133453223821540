// --------------------------------------------------
// +++ TRUMPS BACKGROUND POSITION +++
// --------------------------------------------------

// === OPTIONS ===
// --------------------------------------------------

// TOP-LEFT
// TOP-CENTER
// TOP-RIGHT
// CENTER-LEFT
// CENTER-CENTER
// CENTER-RIGHT
// BOTTOM-LEFT
// BOTTOM-CENTER
// BOTTOM-RIGHT

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === OPTIONS ===
// --------------------------------------------------

// TOP-LEFT

.u-bg--tl {
  background-position: top left !important;
}

// TOP-CENTER

.u-bg--tc {
  background-position: top center !important;
}

// TOP-RIGHT

.u-bg--tr {
  background-position: top right !important;
}

// CENTER-LEFT

.u-bg--cl {
  background-position: center left !important;
}

// CENTER-CENTER

.u-bg--cc {
  background-position: center !important;
}

// CENTER-RIGHT

.u-bg--cr {
  background-position: center right !important;
}

// BOTTOM-LEFT

.u-bg--bl {
  background-position: bottom left !important;
}

// BOTTOM-CENTER

.u-bg--bc {
  background-position: bottom center !important;
}

// BOTTOM-RIGHT

.u-bg--br {
  background-position: bottom right !important;
}

// === DOCS ===
// --------------------------------------------------

/*
---
name: Background-positions
utilities:
  - Background-positions
hidePreview: true
---

**Options:**

```html
<div class="u-bg--[ tl | tc | tr | cl | cc | cr | bl | bc | br ]">...</div>
```

*/
