/* ------------------------------------ *\
  #PAGE SLOGAN
\* ------------------------------------ */

.card__slogan {
  position: absolute;
  top: 40px;
  left: 35px;
  z-index: 1;

  margin: 0;
  padding: 0;

  color: $yom-white;

  font-size: 34px;
  font-weight: 700;

  letter-spacing: -1.08px;
  line-height: 40px;

  @include media-query(portable) {
    top: 25px;
  }

  @include media-query(phone) {
    font-size: 28px;

    line-height: 30px;

    span {
      font-size: 22px;
    }
  }

  span {
    display: block;

    color: $yom-dusty;

    font-size: 28px;
    font-weight: 300;
  }
}
