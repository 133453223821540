// ---------------------------------------------------
// +++ COMPONENT TYPESET +++
// --------------------------------------------------

// === VARIABLES ===
// --------------------------------------------------

// TYPESET-VERTICAL-RHYTM

// === BASE ===
// --------------------------------------------------

// TYPESET

// === PARTS ===
// --------------------------------------------------

// TITLE
// LABEL
// HEADING
// PARAGRAPH

// === OPTIONS ===
// --------------------------------------------------

// TYPESET-PUSH-BOTTOM
// TYPESET-INDENT-HORIZONTAL
// TYPESET-INDENT-VERTICAL
// TYPESET-SQUEEZE

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === VARIABLES ===
// --------------------------------------------------

// TYPESET-VERTICAL-RHYTM

$typeset-vertical-rhytm: 50px;

// === BASE ===
// --------------------------------------------------

// TYPESET

.typeset {
  display: block;

  margin: 0;
  padding: 0;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

// === PARTS ===
// --------------------------------------------------

// TITLE

.typeset__title {
  margin-top: $typeset-vertical-rhytm;
  margin-bottom: $typeset-vertical-rhytm;
}

// LABEL

.typeset__label {
  display: block;

  color: $yom-dusty;

  font-size: 17px;
  font-weight: 300;

  line-height: 30px;
}

// HEADING

.typeset__heading {
  display: block;

  color: $yom-cod;

  font-size: 23px;
  font-weight: 700;

  line-height: 29px;
}

// PARAGRAPH

.typeset__paragraph {
  margin-top: $typeset-vertical-rhytm / 2;
  margin-bottom: 0;
  padding-top: 0;

  color: $yom-dusty;

  font-size: 17px;

  line-height: 30px;

  a {
    color: $yom-cod;

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

// === OPTIONS ===
// --------------------------------------------------

// TYPESET-PUSH-BOTTOM

.typeset--push-bottom {
  margin-bottom: $typeset-vertical-rhytm;
}

// TYPESET-INDENT-HORIZONTAL

.typeset--indent-h {
  padding-right: 55px;
  padding-left: 55px;

  @include media-query(palm) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

// TYPESET-INDENT-VERTICAL

.typeset--indent-v {
  padding-top: 70px;
  padding-bottom: 70px;

  @include media-query(palm) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

// TYPESET-SQUEEZE

.typeset--squeeze {
  .typeset__title {
    margin-bottom: $typeset-vertical-rhytm / 2;
  }
}

// === DOCS ===
// --------------------------------------------------

/*
---
name: Typeset
components:
  - Typeset
---

**Base:**

```html
<section class="typeset">
  <h1 class="typeset__title">
    <span class="typeset__label">
      Client
    </span>
    <span class="typeset__heading">
      TNT
    </span>
  </h1>
  <p class="typeset__paragraph">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et magna aliqua. Ut enim ad minim veniam.
  </p>
  <p class="typeset__paragraph">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et magna aliqua. Ut enim ad minim veniam.
  </p>
  <h2 class="typeset__title">
    <span class="typeset__label">
      Client
    </span>
    <span class="typeset__heading">
      TNT
    </span>
  </h2>
  <p class="typeset__paragraph">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et magna aliqua. Ut enim ad minim veniam.
  </p>
</section>
```

**Options:**

```html
<section class="typeset typeset--[ push-bottom | indent-h | indent-v | squeeze ]">
  ...
</section>
```

*/
