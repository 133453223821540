// --------------------------------------------------
// +++ TRUMPS HIDE +++
// --------------------------------------------------

// === TOOLS ===
// --------------------------------------------------

// HIDE MIXIN

// === BASE ===
// --------------------------------------------------

// HIDE
// HIDDEN-VISUALLY

// === OPTIONS ===
// --------------------------------------------------

// PHONE
// PHONE-PLUS
// PALM
// LAP
// LAP-AND-UP
// PORTABLE
// DESK

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === TOOLS ===
// --------------------------------------------------

// HIDE MIXIN

@mixin hide() {
  display: none !important;
}

// === BASE ===
// --------------------------------------------------

// HIDE

.u-hide { @include hide(); }

// HIDDEN-VISUALLY

.u-hidden-visually {
  @include hidden-visually();
}

// === OPTIONS ===
// --------------------------------------------------

// PHONE

@include media-query(phone) {
  .u-hide--phone { @include hide(); }
}

// PHONE-PLUS

@include media-query(phone-plus) {
  .u-hide--phone-plus { @include hide(); }
}

// PALM

@include media-query(palm) {
  .u-hide--palm { @include hide(); }
}

// LAP

@include media-query(lap) {
  .u-hide--lap { @include hide(); }
}

// LAP-AND-UP

@include media-query(lap-and-up) {
  .u-hide--lap-and-up { @include hide(); }
}

// PORTABLE

@include media-query(portable) {
  .u-hide--portable { @include hide(); }
}

// DESK

@include media-query(desk) {
  .u-hide--desk { @include hide(); }
}

// === DOCS ===
// --------------------------------------------------

/*
---
name: Hide
utilities:
  - Hide
hidePreview: true
---

**Options:**

```html
<div class="u-hide--[ phone | phone-plus | palm | lap | lap-and-up | portable | desk ]">...</div>
```

*/
