// --------------------------------------------------
// +++ BASE HEADINGS +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// H1 (ALPHA)
// H2 (BETA)
// H3 (GAMMA)
// H4 (DELTA)
// H5 (EPSILON)
// H6 (ZETA)

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// H1 (ALPHA)
// H2 (BETA)
// H3 (GAMMA)
// H4 (DELTA)
// H5 (EPSILON)
// H6 (ZETA)

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
