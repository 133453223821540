/* ------------------------------------ *\
  #LISTS
\* ------------------------------------ */

.list-arrow {
  margin: 0;
  padding: 0;

  list-style: none;

  li {
    position: relative;

    margin: 15px 0;
    padding: 0 0 0 30px;

    color: $yom-cod;

    font-size: 17px;
    font-weight: 500;

    &:before {
      position: absolute;
      top: 3px;
      left: 0;

      display: block;
      width: 18px;
      height: 20px;

      background-image: url('../images/list-arrow-right.png');

      content: '';
    }
  }
}
