/* ------------------------------------ *\
  #PROJECT
\* ------------------------------------ */

/* PROJECT */


/* PROJECT-HERO */

.project-hero__title {
  padding: 135px 25px 0 7.5%;

  color: $yom-white;

  font-size: 100px;

  line-height: 96px;

  word-wrap: break-word;

  @include media-query(palm) {
    padding-top: 0;
  }

  @include media-query(phone) {
    font-size: 55px;

    line-height: 51px;
  }
}


/* PROJECT-INTRO */

.project-intro {
  margin-right: 10%;
  margin-left: 18%;

  padding-top: 260px;

  @include media-query(palm) {
    margin: 0 16px;

    padding-top: 65px;
  }
}

/* PROJECT-GRAPHIC */

.project-graphic {
  position: relative;

  width: 100%;
  min-height: 80px;

  background-color: $yom-athens;

  text-align: center;
}

.project-graphic__caption {
  position: absolute;
  right: 0;
  bottom: 50px;
  left: 0;
  z-index: 1;

  margin: 0;
  padding: 0 50px;

  text-align: left;

  @include media-query(phone) {
    padding: 0 25px;
  }
}

.project-graphic__caption--highlight {
  color: $yom-white;
}

.project-graphic__image {
  img {
    width: 100%;
    height: auto;
  }
}

/* OPTIONS */

.project-graphic--fill {
  .project-graphic__image {
    width: 100%;
    height: auto;
  }
}

.project-graphic--shadow {
  .project-graphic__image img {
    box-shadow: 0 0 46px 0 rgba(217, 215, 215, 0.5);
  }
}

.project-graphic--center {
  padding: 0 12.5%;

  .project-graphic__image img {
    max-width: 814px;

    margin: 135px 0;

    text-align: center;
  }
}

.u-1\/2 {
  .project-graphic--center {
    .project-graphic__image img {
      max-width: 520px;
    }
  }
}

/* OLD */

.project__image {
  padding: 0 10%;

  @include media-query(palm) {
    margin-bottom: 30px;
  }

  img {
    width: 100%;

    margin-bottom: 15px;
  }

  span {
    display: block;
  }
}
