// --------------------------------------------------
// +++ COMPONENT TRANSITION +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// TRANSITION

.transition {
  position: fixed;
  z-index: 3;

  width: 0;
  height: 100vh;

  transform: translate3d(0, 0, 0);

  transition-timing-function: ease-in-out;
  animation-duration: 1s;

  background: $yom-cod;
  will-change: width, height;
}

.animating {
  .transition {
    animation-name: swipeInOut;
  }
}

// === KEYFRAMES ===
// --------------------------------------------------

// SWIPEINOUT

@keyframes swipeInOut {
  0% {
    width: 0;
  }

  45% {
    width: 100%;
  }

  55% {
    width: 100%;
    height: 100vh;
  }

  100% {
    width: 100%;
    height: 0;
  }
}
