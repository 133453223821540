/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src:
    local('Open Sans Light'),
    local('OpenSans-Light'),
    url('../fonts/open-sans-v15-latin-300.woff2') format('woff2'),
    url('../fonts/open-sans-v15-latin-300.woff') format('woff');
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'),
    url('../fonts/open-sans-v15-latin-regular.woff') format('woff');
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'),
    url('../fonts/open-sans-v15-latin-700.woff') format('woff');
}

/* domine-regular - latin */
@font-face {
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  src:
    local('Domine'),
    local('Domine-Regular'),
    url('../fonts/domine-v5-latin-regular.woff2') format('woff2'),
    url('../fonts/domine-v5-latin-regular.woff') format('woff');
}

/* domine-700 - latin */
@font-face {
  font-family: 'Domine';
  font-style: normal;
  font-weight: 700;
  src:
    local('Domine Bold'),
    local('Domine-Bold'),
    url('../fonts/domine-v5-latin-700.woff2') format('woff2'),
    url('../fonts/domine-v5-latin-700.woff') format('woff');
}
