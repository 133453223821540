// --------------------------------------------------
// +++ COMPONENT AGENCY-LIST +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// AGENCY-LIST

// === PARTS ===
// --------------------------------------------------

// LINK
// AGENCY-1
// AGENCY-2
// AGENCY-3
// AGENCY-4
// AGENCY-5
// AGENCY-6

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// AGENCY-LIST

.agency-list {
  margin-top: 0;
  margin-bottom: 50px;
  padding: 0 5%;
}

// === PARTS ===
// --------------------------------------------------

// LINK

.agency-list__link {
  display: block;

  margin-right: 30px;
  margin-bottom: 50px;
  padding-right: 25%;

  @include media-query (phone) {
    margin-bottom: 30px;
  }
}

// AGENCY-1

.agency-1 img {
  width: 100%;
  max-width: none;
  height: auto;
}

// AGENCY-2

.agency-2 img {
  width: 90%;
  max-width: none;
  height: auto;
}

// AGENCY-3

.agency-3 img {
  width: 70%;
  max-width: none;
  height: auto;
}

// AGENCY-4

.agency-4 img {
  width: 85%;
  max-width: none;
  height: auto;
}

// AGENCY-5

.agency-5 img {
  width: 100%;
  max-width: none;
  height: auto;
}

// AGENCY-6

.agency-6 img {
  width: 100%;
  max-width: none;
  height: auto;
}
