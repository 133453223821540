// ---------------------------------------------------
// +++ COMPONENT TITLE +++
// --------------------------------------------------

// === VARIABLES ===
// --------------------------------------------------

// TITLE-OFFSET-LEFT

// === BASE ===
// --------------------------------------------------

// TITLE

// === PARTS ===
// --------------------------------------------------

// YEAR

// === OPTIONS ===
// --------------------------------------------------

// SERIF
// OFFSET-TOP
// OFFSET-LEFT @ PALM
// HIGHLIGHT

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === VARIABLES ===
// --------------------------------------------------

// TITLE-OFFSET-LEFT

$title-offset-left: 57%;

// === BASE ===
// --------------------------------------------------

// TYPESET

.title {
  position: relative;

  margin: 0;

  color: $yom-cod;

  font-size: 38px;

  line-height: 45px;
}

// === PARTS ===
// --------------------------------------------------

// YEAR

.title__year {
  display: block;

  margin-top: 20px;

  font-size: 15px;

  line-height: 18px;

  @include media-query(lap-and-up) {
    position: absolute;
    top: 32px;
    left: -75px;

    margin-top: 0;

    transform: rotate(-90deg);
  }
}

// === OPTIONS ===
// --------------------------------------------------

// TITLE-SERIF

.title--serif {
  @include font-domine();
  font-weight: 400;
}

// TITLE-OFFSET-TOP

.title--offset-top {
  top: -85px;
}

// TITLE-OFFSET-LEFT

.title--offset-left\@palm {
  @include media-query(lap-and-up) {
    width: 100% + $title-offset-left;

    margin-left: -$title-offset-left;
  }
}

// HIGHLIGHT

.title--highlight {
  text-shadow: 0 0 90px rgba(255, 255, 255, 0.6);
}

// === DOCS ===
// --------------------------------------------------

/*
---
name: Title
components:
  - Title
---

**Base:**

```html
<h1 class="title title--serif">
  <span class="title__year">2014-2016</span>
  Title
</h1>
```

**Options:**

```html
<h1 class="title title--[ serif | offset-top | offset-left@palm | highlight ]">...</h1>
```

*/
