// --------------------------------------------------
// +++ COMPONENT BUTTON +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// BTN

// === PARTS ===
// --------------------------------------------------

// ICON
// LABEL

// === OPTIONS ===
// --------------------------------------------------

// PRIMARY
// LEFT
// CENTER
// RIGTH

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// BTN

.btn {
  display: inline-block;

  border: 0;

  background-color: transparent;

  color: $yom-cod;

  text-decoration: none;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

// === PARTS ===
// --------------------------------------------------

// ICON

.btn__icon {
  display: inline-block;
  max-width: 34px;

  margin-bottom: 5px;
}

// LABEL

.btn__label {
  display: block;

  font-size: 17px;
}

// === OPTIONS ===
// --------------------------------------------------

// PRIMARY
.btn--primary {
  padding-right: 10px;
  padding-bottom: 15px;
  padding-left: 10px;

  border-bottom: 9px solid $yom-cod;
}

// LEFT

.btn--left {
  text-align: left;
}

// CENTER

.btn--center {
  text-align: center;
}

// RIGHT

.btn--right {
  text-align: right;
}

// === DOCS ===
// --------------------------------------------------

// DOCS

/*
Button

Markup:
<a href="#" class="btn">
  <svg class="btn__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 22">
    <path d="M0 11 0 11c0 0.1 0 0.3 0.1 0.4l11 10.4c0.2 0.2 0.5 0.2 0.7 0 0.2-0.2 0.2-0.5 0-0.7L1.7 11.5h31.8c0.3 0 0.5-0.2 0.5-0.5 0-0.3-0.2-0.5-0.5-0.5H1.7l10.2-9.7c0.2-0.2 0.2-0.5 0-0.7 -0.2-0.2-0.5-0.2-0.7 0l-11 10.4C0 10.7 0 10.8 0 11"/>
  </svg>
  <span class="btn__label">Go back</span>
</a>

Style guide: Base - Button
*/
