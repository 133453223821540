// --------------------------------------------------
// +++ BASE HR (HORIZONTAL-LINE) +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// HORIZONTAL-LINE

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// HORIZONTAL-LINE

hr {
  height: 1px;

  border: 0;

  background: $yom-dusty;
}

// === DOCS ===
// --------------------------------------------------

// DOCS

/*
Horizontal-line

Markup:
<hr>

Style guide: Base - Horizontal-line
*/
