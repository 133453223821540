// --------------------------------------------------
// +++ SETTINGS COLOR +++
// --------------------------------------------------

// === VARIABLES ===
// --------------------------------------------------

// COD
// DUSTY
// ATHENS
// WHITE

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === VARIABLES ===
// --------------------------------------------------

// COD

$yom-cod: #171717;

// DUSTY

$yom-dusty: #767676;

// ATHENS

$yom-athens: #f8f8fa;

// WHITE

$yom-white: #fff;

// === DOCS ===
// --------------------------------------------------

// DOCS

/*
Colors

http://chir.ag/projects/name-that-color/#ff0033 used for color naming.

Hidemarkup:

Markup:
<div class="layout">
  <div class="layout__item u-1/4">
    <div style="background-color: #171717; color:#fff; padding: 20px;" class="u-1/1">
      $yom-cod
    </div>
  </div><div class="layout__item u-1/4">
    <div style="background-color: #999; color:#fff; padding: 20px;" class="u-1/1">
      $yom-dusty
    </div>
  </div><div class="layout__item u-1/4">
    <div style="background-color: #f8f8fa; color:#999; padding: 20px;" class="u-1/1">
      $yom-athens
    </div>
  </div><div class="layout__item u-1/4">
    <div style="background-color: #fff; color:#999; padding: 20px;" class="u-1/1">
      $yom-white
    </div>
  </div>
</div>

Style guide: Settings - Colors
*/

/*
Variables

Hideexample: true

Markuplanguage: sass

Markup:
$yom-cod: #171717;
$yom-dusty: #999999;
$yom-athens: #f8f8fa;
$yom-white: #ffffff;

Style guide: Settings - Colors - Variables
*/
