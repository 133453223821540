// ---------------------------------------------------
// +++ COMPONENT FOOTER +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// FOOTER

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// FOOTER

.footer {
  width: 100%;

  margin-top: 100px;
  margin-bottom: 75px;
  padding: 0 6%;
}

// === DOCS ===
// --------------------------------------------------

// DOCS

/*

Coming soon...

*/
