// ---------------------------------------------------
// +++ COMPONENT INTRO +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// INTRO

// === PARTS ===
// --------------------------------------------------

// SCHERM-1

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// INTRO

.intro {
  height: 100%;

  margin-left: 18%;
}

// === PARTS ===
// --------------------------------------------------

.scherm-1 {
  @include media-query(desk) {
    margin-top: 100px;
  }
}

// === DOCS ===
// --------------------------------------------------

// DOCS

/*

Coming soon...

*/
