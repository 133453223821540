// --------------------------------------------------
// +++ BASE DOCUMENT +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// HTML + BODY

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// HTML + BODY

html,
body {
  width: 100%;

  @include font-open-sans();
  font-weight: 300;
}
