// --------------------------------------------------
// +++ TRUMPS TEXT +++
// --------------------------------------------------

// === OPTIONS ===
// --------------------------------------------------

// BREAK-WORD

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === OPTIONS ===
// --------------------------------------------------

// BREAK-WORD

.u-txt--break-word {
  word-wrap: break-word;
}

// === DOCS ===
// --------------------------------------------------

/*
---
name: Text
utilities:
  - Text
hidePreview: true
---

**Options:**

```html
<p class="u-txt--[ break-word ]">...</p>
```

*/
