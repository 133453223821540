// --------------------------------------------------
// +++ LAYOUT +++
// --------------------------------------------------

// === OPTIONS ===
// --------------------------------------------------

// ITEM-LEFT
// ITEM-RIGHT

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === OPTIONS ===
// --------------------------------------------------

// ITEM-LEFT

.layout__item--left {
  text-align: left;
}

// ITEM-RIGHT

.layout__item--right {
  text-align: right;
}

// === DOCS ===
// --------------------------------------------------

// DOCS

/*

Coming soon!

*/
