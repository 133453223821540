/* ------------------------------------ *\
  #HEADER
\* ------------------------------------ */

.header {
  position: relative;

  width: 100%;
}

.site-navigation {
  position: relative;

  margin-bottom: 33px;
  padding-top: 20px;

  @include media-query(phone) {
    margin-bottom: 140px;
    padding-top: 0;
  }

  .btn {
    position: absolute;
    bottom: 0;
    left: 0;

    float: left;

    margin-left: 95px;

    @include media-query(portable) {
      bottom: -20px;

      margin-left: 30px;
    }

    @include media-query(phone) {
      bottom: -110px;

      margin-left: 15px;
    }
  }
}

.site-navigation--home {
  @include media-query(phone) {
    margin-bottom: 33px;
  }
}

.site-navigation__push {
  margin-left: 67.5%;
  padding-left: 4.5%;

  @include media-query(portable) {
    width: 100%;

    margin-left: 0;
    padding-top: 0;
    padding-right: 32px;
    padding-left: 0;

    text-align: right;
  }

  @include media-query(phone) {
    padding-right: 0;
  }
}

.yom {
  color: $yom-cod;

  font-size: 53px;
  font-weight: 700;

  letter-spacing: -1.6px;
  line-height: 53px;

  text-decoration: none;

  @include media-query(phone) {
    display: inline-block;

    margin-top: 32px;
    padding: 0 16px;

    text-align: left;
  }
}

.yom-slogan {
  display: none;
}

.yom-slogan--display {
  @include media-query(portable) {
    display: block;
    width: 100%;

    padding: 0;

    color: $yom-cod;

    @include font-domine();
    font-size: 28px;

    line-height: 34px;
  }

  @include media-query(phone) {
    margin: 20px 0 30px;
    padding-right: 16px;
    padding-left: 15px;

    font-size: 22px;

    line-height: 30px;
  }
}

.yom-open {
  position: absolute;
  top: 30px;
  left: 30px;

  margin: 0;
  padding: 0;

  @include font-domine();
  font-size: 15px;

  @include media-query(portable) {
    display: none;
  }
}

.yom-open__indent {
  display: block;

  padding-left: 35px;
}
