// --------------------------------------------------
// +++ MIXINS +++
// --------------------------------------------------

// === MIXINS ===
// --------------------------------------------------

// BORDER-RADIUS
// TRANSFORM
// TRANSITION
// HIDDEN-VISUALLY

// === DOCS ===
// --------------------------------------------------

// DOCS

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------

// BORDER-RADIUS

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// TRANSFORM

@mixin transform($type) {
  -webkit-transform: $type;
  -moz-transform: $type;
  -ms-transform: $type;
  -o-transform: $type;
  transform: $type;
}

// TRANSITION

@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

// HIDDEN-VISUALLY

@mixin hidden-visually() {
  position: absolute !important;

  width: 1px !important;
  height: 1px !important;

  margin: -1px !important;
  padding: 0 !important;

  border: 0 !important;

  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;

  white-space: nowrap !important;

  overflow: hidden !important;
}

// === DOCS ===
// --------------------------------------------------

// DOCS

/*
Mixins

**When to use a mixin?**

Hideexample: true

Markuplanguage: sass

Markup:
.class {
  @include mixin(property);
}

Style guide: Tools - Mixins
*/

/*
Border-radius

For including backwards-compatible border-radius.

**How to use:**

Hideexample: true

Markuplanguage: sass

Markup:
.class {
  @include border-radius(5px);
}

Style guide: Tools - Mixins - Border-radius
*/

/*
Transform

Standardized use of the transform property.

**How to use:**

Hideexample: true

Markuplanguage: sass

Markup:
.class {
  @include transform($type);
}

Style guide: Tools - Mixins - Transform
*/

/*
Transition

Standardized use of transition animation.

**How to use:**

Hideexample: true

Markuplanguage: sass

Markup:
.class {
  @include transition($transition-property, $transition-time, $method);
}

Style guide: Tools - Mixins - Transition
*/
