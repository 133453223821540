// --------------------------------------------------
// +++ GENERIC FONTS +++
// --------------------------------------------------

// === BASE ===
// --------------------------------------------------

// FONT OPEN-SANS
// FONT DOMINE

// --------------------------------------------------
// +++ END +++
// --------------------------------------------------


// === BASE ===
// --------------------------------------------------

// FONT OPEN-SANS

@mixin font-open-sans() {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

// FONT DOMINE

@mixin font-domine {
  font-family: 'Domine', Times, Georgia, serif;
}
