/* ------------------------------------ *\
  #ANCHORS
\* ------------------------------------ */

.anchor {
  color: $yom-cod;

  font-size: 24px;
  font-weight: 700;

  line-height: 30px;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.anchor--arrow {
  padding-right: 30px;

  background-image: url('../images/list-arrow-right.png');
  background-repeat: no-repeat;
  background-position: right 8px;
}
